import React, { useState, useEffect, useRef } from "react";
import {
  AnswerContainer,
  BackCenter,
  BodyInformation,
  BodyWrapper,
  BoxArea,
  BusinessScale,
  BusinessTool,
  ButtonArea,
  ButtonPress,
  ButtonPressBlack,
  Footer,
  FooterContent,
  Frequently,
  Header,
  ImageCollab,
  ImageHolder,
  LogoArea,
  LogoDiv,
  MainWrapper,
  MerchroEnglish,
  MerchroNavsArea,
  NavArea,
  Questionaira,
  Questions,
  QuestionsAre,
  ShortBox,
  ShortTextInBox,
  TextArea,
  ToolInformation,
  UseFulArea,
  ViewImage,
} from "./style";
import {
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";
import {
  Fade,
  Zoom,
} from "react-awesome-reveal";
import { FaTwitter , FaInstagram } from "react-icons/fa";

interface Question {
  id: number;
  text: string;
  answer: string;
}

const InvestContent = () => {
  const [toggledQuestion, setToggledQuestion] = useState<number | null>(null);

  const handleToggle = (questionId: number) => {
    setToggledQuestion((prev) => (prev === questionId ? null : questionId));
  };

const questions: Question[] = [
    {
      id: 1,
      text: "What is the Mage Savings Plan?",
      answer: "The Mage Savings Plan is a unique financial service tailored for creatives, offering an attractive savings platform with multiple benefits. It helps you secure your future while providing additional perks like free health coverage and access to zero-interest loans."
    },
    {
      id: 2,
      text: "What interest rate will I earn on my savings?",
      answer: "You will earn an impressive 15% interest annually on your savings, helping you grow your funds faster."
    },
    {
      id: 3,
      text: "What does the free health plan include?",
      answer: "With every Mage Savings Plan, you are automatically enrolled in a free health plan. This coverage provides essential health services, ensuring peace of mind as you save for your future."
    },
    {
      id: 4,
      text: "What are zero-interest loans, and how do they work?",
      answer: "Mage offers zero-interest loans for 30 days to help cover unexpected expenses or opportunities without affecting your savings. You can borrow up to a certain percentage of your savings balance and pay back within 30 days, all without interest!"
    },
    {
      id: 5,
      text: "How do the monthly draws work?",
      answer: "When you save with Mage, you are automatically entered into monthly draws where you stand a chance to win exciting rewards, including cash prizes, creative tools, and other special offers!"
    },
    {
      id: 6,
      text: "Are there any other benefits?",
      answer: "Yes, the Mage Savings Plan offers even more, including exclusive access to financial literacy content, personalized investment advice, and special discounts on creative industry tools and services."
    },
    {
      id: 7,
      text: "Is there a minimum deposit to start saving?",
      answer: "There is no minimum deposit required to open a Mage Savings Plan. You can start with any amount that suits your financial goals."
    },
    {
      id: 8,
      text: "How do I withdraw my savings?",
      answer: "You can withdraw your savings at any time through our easy-to-use platform. However, we encourage long-term saving to maximize the benefits of the high-interest rate."
    },
    {
      id: 9,
      text: "How secure are my savings?",
      answer: "Your savings are safe and secure. Mage partners with reputable financial institutions and ensures full regulatory compliance, so you can trust that your funds are protected."
    },
    {
      id: 10,
      text: "How can I sign up for the Mage Savings Plan?",
      answer: "You can sign up through our website or mobile app in just a few minutes. Simply create an account, select the savings plan, and start securing your financial future today!"
    }
  ];

  const businessScaleRef = useRef<HTMLDivElement>(null);

  const handleScrollToBusinessScale = () => {
    if (businessScaleRef.current) {
      businessScaleRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const advantage = [
    {
      image: "/lock.png",
      topic: "Secured Returns",
      desc:
        "Mage uses the highest level of internet Security to ensure that your information and money is completely protected from fraud.",
    },
    {
      image: "/report.png",
      topic: "Free HMO",
      desc:
        "We've included a free health plan with our savings plans so you can stay proteced as you make money.",
    },
    {
      image: "/impact.png",
      topic: "No Interest Loans",
      desc:
        "Get a 0% loan when you have an active savings plan on Mage. No need to stop your savings when you need cash.",
    },
  ];

  const handleButtonClick = (path = '') => {
    // Link to form
    if (path === 'login') {
      window.open("https://app.trymage.com/login");
      return;
    }
    if (path === 'register') {
      window.open("https://app.trymage.com/sign-up");
      return;
    }
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSde1mz7D5afQSuSi3tafVirOVL8VGneh8LjflKP5WyJj4EyiA/viewform?usp=sf_link", "_blank");
  };

  const handlePathClick = (path:string) => {
    window.location.href = 'https://trymage.com';
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const originalContent = container.children[0];

    const cloneContent = () => {
      const clone = originalContent?.cloneNode(true);
      container?.appendChild(clone);
    };

    const handleAnimationIteration = () => {
      const firstChild = container?.firstChild;
      if (firstChild) {
        container?.removeChild(firstChild);
      }
      cloneContent();
    };
    cloneContent();

    container?.addEventListener("animationiteration", handleAnimationIteration);
    return () => {
      container?.removeEventListener(
        "animationiteration",
        handleAnimationIteration
      );
    };
  }, []);

  return (
    <>
      <MainWrapper>
        <Header ref={businessScaleRef} style={{ background: "#fff", borderColor: '#ccc' }}>
          <Zoom triggerOnce>
            <LogoDiv style={{ cursor: 'pointer' }} onClick={() => handlePathClick('home')}>
              <img src="/Group2.svg" alt="logo" />
            </LogoDiv>
          </Zoom>

          <NavArea></NavArea>
          <ButtonArea>
            {/* <p style={{ color: '#000', marginRight: 20 }} onClick={() => handlePathClick('home')}>Home</p> */}
            <p style={{ color: '#000', marginRight: 20 }} onClick={() => handleButtonClick('register')}>Get started</p>
          </ButtonArea>
        </Header>

        <BodyWrapper style={{ background: '#fff' }}>
          <BackCenter>
            <ButtonPressBlack>Save and Invest</ButtonPressBlack>
            <TextArea style={{ width: "auto" }}>
              <h3 style={{ width: '100%', color: '#000' }} className="close-text">The Savings Plan that grows with you</h3>
              <p style={{ color: '#000' }}>
                Mage helps creatives and digital workers grow their savings
                <br /> while enjoying juicy benefits.
              </p>

              <ButtonPress onClick={() =>handleButtonClick('register')}>Get Started</ButtonPress>
            </TextArea>
          </BackCenter>
          <ViewImage>
            <img style={{ width: '95%', margin: '0 2.5%' }} src="/invest.png" alt="invest" />
          </ViewImage>
        </BodyWrapper>

        <BodyInformation>
          <ImageCollab>
            <img src="/groups5.png" alt="collab" />
          </ImageCollab>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextArea width="65%" bottom="70px">
              <>
                <h2
                  style={{
                    color: "#222",
                  }}
                  className="close-text"
                >
                  Tailored savings for new money
                </h2>
                <p style={{ color: "#222" }}>
                  Customize your savings plan to fit your vibe. No hassles.
                </p>
              </>
            </TextArea>
          </div>

          <BoxArea>
            <ShortBox className="invest-box">
              <ShortTextInBox>
                <div>
                  <img src="/c-collect.svg" alt="logo" />
                </div>
                <p style={{ margin: '15px 0' }}>Regular Savings</p>
                <span>
                    Earn up to 15% interest yearly when you set up saving plan. Better than your bank account.
                </span>
              </ShortTextInBox>
            </ShortBox>
            <ShortBox className="invest-box">
              <ShortTextInBox>
                <div>
                  <img src="/c-connect.svg" alt="logo" />
                </div>
                <p style={{ margin: '15px 0' }}>Squad Savings</p>
                <span>
                    Want to go on that tour, buy that new equipment, or host that event, it's easier when you save with your squad.
                </span>
              </ShortTextInBox>
            </ShortBox>
            <div style={{ display: 'flex', justifyContent: "center", textAlign: 'center', width: '100%' }}>
                <ButtonPress style={{ marginTop: 20, marginBottom: -20 }} onClick={() => handleButtonClick('register')}>Get Started</ButtonPress>
            </div>
          </BoxArea>
        </BodyInformation>

        <BusinessScale style={{ background: '#fff' }}>
          <ImageHolder>
            <img src="/picmix.png" alt="landing" />
          </ImageHolder>
          <TextArea width="65%" bottom="70px">
              <>
                <h2
                  style={{
                    color: "#222",
                    marginTop: 30
                  }}
                  className="close-text"
                >
                  Start saving the creative way
                </h2>
              </>
            </TextArea>
          <BusinessTool>
            {advantage.map((info, index) => (
              <ToolInformation key={index} style={{ margin: '0 0 60px 0' }}>
                <div style={{ position: "relative" }}>
                  <div style={{ display: 'block', position: 'absolute', top: -50 }}>
                    {/* <Reveal> */}
                      <img src={info.image} style={{ width: 40 }} alt="stack" />
                    {/* </Reveal> */}
                  </div>
                  <p style={{ color: '#000', fontWeight: 500, display: 'block' }}>{info.topic}</p>
                </div>
                <Fade>
                  <span style={{ color: '#2F2C2C' }}>{info.desc}</span>
                </Fade>
              </ToolInformation>
            ))}
          </BusinessTool>
        </BusinessScale>

        <Questionaira style={{ background: '#fff' }}>
          <Frequently>
            <div>
                <h3 style={{ color: "#000" }} className="close-text">Frequently asked questions</h3>
            </div>
          </Frequently>
          <QuestionsAre>
            {questions.map((question, index) => (
              <Questions key={index} style={{ borderColor: '#000'}}>
                <div onClick={() => handleToggle(question.id)}>
                  <p style={{ color: '#000' }}>{question.text}</p>
                  <span onClick={() => handleToggle(question.id)}>
                    {toggledQuestion === question.id ? (
                      <AiOutlineUp
                        onClick={() => handleToggle(question.id)}
                        color="black"
                        size={20}
                        strokeWidth={6}
                      />
                    ) : (
                      <AiOutlineDown
                        onClick={() => handleToggle(question.id)}
                        color="black"
                        size={20}
                      />
                    )}
                  </span>
                </div>
                {toggledQuestion === question.id && (
                  <>
                    <AnswerContainer
                      isVisible={toggledQuestion === question.id}
                    >
                      <span style={{ color: '#2f2f2f', fontSize: 20, width: '80%' }}>
                        {question.answer}
                      </span>
                    </AnswerContainer>
                  </>
                )}
              </Questions>
            ))}
          </QuestionsAre>
        </Questionaira>

        <Footer>
          <FooterContent>
            <MerchroNavsArea>
              <LogoArea>
                <div>
                  <i>
                    <>
                      <img src="/logofooter.png" alt="logo" onClick={handleScrollToBusinessScale} />
                    </>
                  </i>
                  <div>
                    <i>
                      <>
                        <p>Smart Funding for Creative Projects</p>
                      </>
                    </i>
                  </div>
                </div>
              </LogoArea>
              <UseFulArea>
                <div>
                  <>
                    <p>Contact us</p>
                  </>
                  <>
                    <span>hello@trymage.com</span>
                  </>
                  <>
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      +1 22 5239 2278
                    </span>
                  </>
                  <>
                    <div>
                    <a href="https://twitter.com/try_mage" target="_blank" rel="noreferrer"><FaTwitter color="#fff" size={25} /></a>
                    <a href="https://instagram.com/try_mage" target="_blank" rel="noreferrer"><FaInstagram color="#fff" size={25} /></a>
                    </div>
                  </>
                </div>
               
              </UseFulArea>
            </MerchroNavsArea>
            <MerchroEnglish>
              <div>
                <>
                  <span>&copy; Merchro Inc</span>
                </>
              </div>
              <div>
                <>
                  <span></span>{" "}
                </>
              </div>
            </MerchroEnglish>
          </FooterContent>
        </Footer>
      </MainWrapper>
    </>
  );
};

export default InvestContent;
